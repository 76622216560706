"use client";
import { Notification } from "@mantine/core";
import Image from "next/image";
import Link from "next/link";

import {
  ArrowForwardIcon,
  Button,
  CheckIcon,
  List,
  Flex,
  Center,
  Group,
  Stack,
  Container,
  H5,
  H2,
  Text,
  H1,
  StarIcon,
} from "@/components/ui/elements";

import classes from "./_css/Home.module.css";
import { HomeFooter } from "./HomeFooter";

export function Home() {
  const getEndOfMonth = () => {
    const date = new Date();
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const month = lastDay.getMonth() + 1;
    const day = lastDay.getDate();
    const dayOfWeek = ["日", "月", "火", "水", "木", "金", "土"][
      lastDay.getDay()
    ];

    return `${month}月${day}日(${dayOfWeek})`;
  };
  return (
    <>
      {/* <Header /> */}
      <Container px={40}>
        <Flex className={classes.wrapper}>
          <Center maw={"100%"}>
            <Group className={classes.inner}>
              <Group>
                <Stack gap={0}>
                  <H5>あなたにぴったりの求人がすぐ見つかる</H5>
                  <Stack gap={"xs"}>
                    <H2>【介護特化型】単発バイトアプリ</H2>
                    <Group>
                      <Image
                        src={"/care-link-logo.png"}
                        width={80}
                        height={80}
                        alt=""
                      />
                      <H1>ケアリンク</H1>
                    </Group>
                  </Stack>
                </Stack>
                <Stack>
                  <H5>３つのメリット</H5>
                  <List spacing="sm" size="sm" icon={<CheckIcon size={12} />}>
                    <List.Item>
                      <b>今すぐ始められる</b>
                      <br />– 面接・履歴書不要！アプリで完結！
                    </List.Item>
                    <List.Item>
                      <b>好きな時だけ働ける</b>
                      <br />– 半日勤務OK！介護の単発求人がすぐ見つかる。
                    </List.Item>
                    <List.Item>
                      <b>お金がすぐにもらえる</b>
                      <br />– 働いた当日に報酬がもらえる。
                    </List.Item>
                  </List>
                  <Link href={"/work"} data-testid="link-to-work">
                    <Button size="xs">求人一覧へ</Button>
                  </Link>
                  <Text>
                    ＊現在は東京都・千葉県・埼玉県・神奈川県・静岡県の求人が掲載中です。
                    <br />
                    求人数はまだ限られていますが、徐々にエリアを拡大し、求人数も増やして行きます。
                  </Text>

                  <Group justify="right">
                    <Notification
                      withCloseButton={false}
                      radius="lg"
                      color="yellow"
                      icon={<StarIcon />}
                      withBorder
                      title="初回勤務完了でAmazonギフト券5,000円分プレゼント"
                    >
                      ＊期限：{getEndOfMonth()}23:00まで
                    </Notification>
                    <Button
                      component={Link}
                      href="/verification"
                      radius="xl"
                      size="md"
                      rightSection={<ArrowForwardIcon color="white" />}
                      data-testid="link-to-verification"
                    >
                      アカウント登録またはログイン
                    </Button>
                  </Group>
                </Stack>
              </Group>
              <div>
                <Image
                  src={"/images/hero/pixta_92487044_S.jpg"}
                  width={640}
                  height={452}
                  className={classes.image}
                  alt="ケアリンクイメージ"
                  sizes="(max-width: 40em) 180px 180px"
                />
              </div>
            </Group>
          </Center>
        </Flex>
        <HomeFooter />
      </Container>
    </>
  );
}
