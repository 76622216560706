"use client";
import { useEffect, useState } from "react";

import { Transition } from "@mantine/core";
import { useViewportSize, useWindowScroll } from "@mantine/hooks";
import Link from "next/link";

import { ArrowForwardIcon, Button } from "@/components/ui/elements";

import classes from "./_css/HomeFooter.module.css";

export function HomeFooter() {
  const [scroll, scrollTo] = useWindowScroll();
  const { height, width } = useViewportSize();
  const [isScrollBottom, setIsScrollBottom] = useState(false);

  useEffect(() => {
    const documentHeight = document.body.clientHeight;
    setIsScrollBottom(scroll.y + height + 70 >= documentHeight);
  }, [scroll.y, height]);

  return (
    <Transition mounted={!isScrollBottom} transition="slide-up">
      {(styles) => (
        <footer className={classes.footer} style={styles}>
          <div className={classes.inner}>
            <Button
              component={Link}
              href="/verification"
              rightSection={<ArrowForwardIcon color="white" />}
            >
              アカウント登録またはログイン
            </Button>
          </div>
        </footer>
      )}
    </Transition>
  );
}
